import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Select from "react-select";
import PresencialVista from "./PresencialVista";

function PresencialOffer(props) {
  const [dataarea, setDataArea] = useState("");
  const [facultad, setFacultad] = useState("");
  const [convocatoria, setConvocatoria] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const updateWindowDimensions = () => {
    const newW = window.innerWidth;
    setWidth(newW);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
  }, []);

  function handleChange(e) {
    const { value, checked } = e.target;
    if (checked) {
      setFacultad(value);
    } else {
      setFacultad("0");
    }
  }

  function handleConvocatoria(e) {
    const { value, checked } = e.target;
    if (checked) {
      setConvocatoria(value);
    } else {
      setConvocatoria("0");
    }
  }

  function handleConvocatoriaMobile(value) {
    setConvocatoria(value);
  }
  function handleChangeMobile(value) {
    setFacultad(value);
  }

  const optionsfacultad = [
    {
      value: "Ciencias de la Salud",
      label: "Ciencias de la Salud",
    },
    {
      value: "Ciencias Económicas y Empresariales",
      label: "Ciencias Económicas y Empresariales",
    },
    {
      value: "Ciencias Exactas y Naturales",
      label: "Ciencias Exactas y Naturales",
    },
    {
      value: "Ciencias Sociales, Educación y Humanidades",
      label: "Ciencias Sociales, Educación y Humanidades",
    },
    {
      value: "Ciencias Jurídicas y Políticas",
      label: "Ciencias Jurídicas y Políticas",
    },
    {
      value: "Ingenierías y Arquitectura",
      label: "Ingenierías y Arquitectura",
    },
    { value: "0", label: "-" },
  ];

  const optionsconvocatoria = [
    {
      value: "Admisión",
      label: "Admisión",
    },
    {
      value: "Diagnóstica",
      label: "Diagnóstica",
    },
    { value: "0", label: "-" },
  ];

  return (
    <div className="layout9">
      <div className="wrapper-titleoffer ">
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              stiffness: 200,
            },
          }}
        >
          {props.title}
        </motion.div>
      </div>
      <h2>{props.subtitle}</h2>
      <div className="offerbymodelo">
        {width > 900 && (
          <div>
            <div className="layout9 ">
              <p>
                <b>Prueba de Admisión</b>
              </p>
              <div className="item">
                <input
                  type="radio"
                  name="convocatoria"
                  value="0"
                  id="todass"
                  onChange={handleConvocatoria}
                />
                <label className="form-check-label" htmlFor="todass">
                  Todas
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="convocatoria"
                  value="Admisión"
                  id="abril"
                  onChange={handleConvocatoria}
                />
                <label className="form-check-label" htmlFor="abril">
               Selección
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="convocatoria"
                  value="Diagnóstica"
                  id="octubre"
                  onChange={handleConvocatoria}
                />
                <label className="form-check-label" htmlFor="octubre">
              Diagnóstica
                </label>
              </div>
              <p>
                <b>Carreras por Facultad</b>
              </p>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="0"
                  id="todas"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="todas">
                  Todas
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ciencias de la Salud"
                  id="salud"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="salud">
                  Ciencias de la Salud
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ciencias Económicas y Empresariales"
                  id="empresas"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="empresas">
                  Ciencias Económicas y Empresariales
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ciencias Exactas y Naturales"
                  id="ciencias"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="ciencias">
                  Ciencias Exactas y Naturales
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ciencias Sociales, Educación y Humanidades"
                  id="sociales"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="sociales">
                  Ciencias Sociales, Educación y Humanidades
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ciencias Jurídicas y Políticas"
                  id="juridicas"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="juridicas">
                  Ciencias Jurídicas y Políticas
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Facultad de Ingenierías y Arquitectura"
                  id="ing"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="ing">
                  Ingenierías y Arquitectura
                </label>
              </div>

              <p>
                <b>Área de interés</b>
              </p>

              <div className="wrapper-inputoffer">
                <input
                  className="input-academicoffer"
                  name="area"
                  value={dataarea}
                  onChange={(e) => setDataArea(e.target.value)}
                  placeholder="Ejemplo: empresas"
                ></input>
                <span className="material-icons">search</span>
              </div>
            </div>
          </div>
        )}

        {width < 900 && (
          <div>
            <p>
              <b>Admisión</b>
            </p>
            <Select
              options={optionsconvocatoria}
              defaultValue={convocatoria}
              onChange={handleConvocatoriaMobile}
            ></Select>{" "}
            <p>
              <b>Carreras por Facultad</b>
            </p>
            <Select
              options={optionsfacultad}
              defaultValue={facultad}
              onChange={handleChangeMobile}
            ></Select>
            <p>
              <b>Área de interés</b>
            </p>
            <div className="wrapper-inputoffer">
              <input
                className="input-academicoffer"
                name="area"
                value={dataarea}
                onChange={(e) => setDataArea(e.target.value)}
                placeholder="Ejemplo: empresas"
              ></input>
              <span className="material-icons">search</span>
            </div>
          </div>
        )}

        <div>
          {width > 900 && (
            <PresencialVista
              title="Elige tu carrera"
              keyword={dataarea.trimEnd().toLowerCase()}
              facultad={facultad}
              convocatoria={convocatoria}
            ></PresencialVista>
          )}
          {width < 900 && (
            <PresencialVista
              title="Elige tu carrera"
              keyword={dataarea.trimEnd().toLowerCase()}
              facultad={facultad.value}
              convocatoria={convocatoria.value}
            ></PresencialVista>
          )}
        </div>
      </div>
    </div>
  );
}

export default PresencialOffer;
