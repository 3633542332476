import BlockAcademicOffer from "../components/BlockAcademicOffer";
import FixedHeader from "../components/FixedHeader";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import BannerGeneral from "../components/BannerGeneral";
import Block4ColumnsText from "../components/Block4ColumnsText";
import BlockLeftImage from "../components/BlockLeftImage";
import FixedNotification from "../components/FixedNotification";

import logothe from "../img/logothe.png";
import DataFetcherNews from "../hooks/fetchNews";

function NewHome() {
  return (
    <>
      <FixedHeader
        video="https://utpl.edu.ec/recursos/videos/utpl.mp4"
        src="https://utpl.edu.ec/recursos/img/cabecera-diciembre-2024.png"
        span="1"
        span0={
          <>
            Somos la
            <b> MEJOR</b>
          </>
        }
        span1="UNIVERSIDAD EN "
        span2={<> EDUCACIÓN EN LÍNEA</>}
        span3=" EN ECUADOR"
        span4={
          <div className="icon150-nomargin">
            <img src={logothe}></img>
          </div>
        }
      ></FixedHeader>

      <BlockAcademicOffer></BlockAcademicOffer>

      {/*
      <BlockRightImage
        content={
          <>
            <h2>
              Impulsa tu futuro con un{" "}
              <b>título UTPL válido en Estados Unidos</b>
            </h2>
            <p>
              Accede a mayores oportunidades laborales y obtén el reconocimiento
              profesional que fortalecerá tu perfil en el mercado laboral
              internacional.{" "}
            </p>
            <p><b>¡Con la UTPL el mundo está a tu alcance!</b></p>

            <a href="/validacion-internacional">Más información</a>
          </>
        }
        src="https://eventos.utpl.edu.ec/sites/default/files/images/img-titulo-valido.png"
      ></BlockRightImage>
       
      <img src={img1}></img>
     
      <div className="graybg wrapper-simulador">
        <BlockLeftImage
          src={data.videoTemplate.imageBlock7.url}
          alt={data.videoTemplate.imageBlock7.title}
          content={documentToReactComponents(
            data.videoTemplate.contentBlock7.json
          )}
        ></BlockLeftImage>
      </div>*/}

      <DataFetcherNews url="https://noticias.utpl.edu.ec/parque/servicio-utpl.json"></DataFetcherNews>
      <div className="icon300 graybg">
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/costos.gif"
          content={
            <>
              <h2>
                {" "}
                Simulador <b>de costos</b>
              </h2>
              <p>
                Aplica para estudiantes nuevos de carreras - tecnologías en
                línea y a distancia
              </p>
              <p>
                {" "}
                Explora los costos, las opciones de becas y variedad de
                descuentos diseñados para ti. ¡Haz tu matrícula ahora mismo y da
                el primer paso hacia una educación superior que se adapte a tus
                necesidades!
              </p>

              <a href="https://matriculas.utpl.edu.ec">Simular ahora</a>
            </>
          }
        ></BlockLeftImage>
      </div>

      <BlockBackgroundImage
        src="https://utpl.edu.ec/recursos/img/bg-centros.png"
        content={
          <>
            <h2>
              La UTPL se expande a nivel <b> nacional e internacional</b>
            </h2>
            <p>
              <b>Centros UTPL</b>
            </p>
            <p>
              Comprometidos con brindarte una educación de calidad, ampliamos
              nuestra cobertura a través de más de 80 centros nacionales e
              internacionales, donde encontrarás recursos y asistencia para tu
              desarrollo académico integral.
            </p>
            <p>
              Encuentra la ubicación de tu centro más cercano en nuestro
              directorio institucional.
            </p>
            <p>
              <a target="_blank" href="https://directorio.utpl.edu.ec/centros">
                Buscar centro UTPL
              </a>
            </p>
          </>
        }
      ></BlockBackgroundImage>

      <div className="estudiar-utpl padding">
        <Block4ColumnsText
          col1={
            <>
              <h2>
                ¿Por qué estudiar <b>en la UTPL?</b>
              </h2>
              <p>
                Nuestros{" "}
                <b>más de 53 años de experiencia en formación universitaria</b>{" "}
                y más de 42 mil estudiantes matriculados de grado, posgrado,
                tecnologías y formación permanente avalan nuestra calidad
                académica y excelencia educativa.
              </p>
            </>
          }
          col2={
            <>
              <h3>
                Emprendimiento e <b>innovación</b>
              </h3>
              <p>
                Por sexto año consecutivo, recibimos el reconocimiento como la
                <b>
                  {" "}
                  "Institución Académica con Mayor Impacto en Innovación"
                </b>{" "}
                por la Alianza para el Emprendimiento e Innovación (AEI). Este
                logro reafirma nuestro compromiso con la transferencia de
                conocimiento y nuestro papel como agentes de cambio para un
                futuro sostenible y emprendedor.
              </p>
            </>
          }
          col3={
            <>
              <h3>
                Reconocimiento <b>nacional</b>
              </h3>
              <p>
                Nos posicionamos en el{" "}
                <b>
                  segundo lugar del Interdisciplinary Science Rankings (ISR)
                  2025 de Times Higher Education (THE).{" "}
                </b>
                Este reconocimiento destaca nuestra capacidad para impulsar
                investigaciones interdisciplinarias que generan soluciones
                innovadoras para los desafíos globales.
              </p>
              <p></p>
            </>
          }
          col4={
            <>
              <h3>
                Excelencia <b>científica</b>
              </h3>
              <p>
                Nuestra docente{" "}
                <b>
                  Ximena Jaramillo fue galardonada con el premio internacional
                  "Mujeres en la Ciencia"{" "}
                </b>
                de L’Oréal y UNESCO por su innovador proyecto de filtros de agua
                elaborados con residuos agroindustriales y minerales locales. Su
                trabajo aporta soluciones sostenibles a comunidades afectadas
                por la contaminación y la falta de agua potable.
              </p>
            </>
          }
        ></Block4ColumnsText>
      </div>

      <BannerGeneral></BannerGeneral>

 
    </>
  );
}

export default NewHome;
