import BlockLeftImage from "./BlockLeftImage";
import Accordion from "./Accordion";

import "../css/blockpagos.css";

function PresencialPagos() {
  return (
    <>
      <div className="icon350 descuentos-section" id="descuentos">
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/costos.gif"
          content={
            <>
              <h3>
                Postula a becas y accede a <b>descuentos hasta el 45% </b>
              </h3>
             
              <p class="yellow">
                <b>Descuentos:</b>
              </p>
              <p> Si has sido admitido en una de nuestras carreras, descubre los
              descuentos que ofrecemos.</p>
              <p>
                🟢 <b>8% de descuento </b> al pagar el programa. 🎓✨
              </p>
              <p>
                🟢 <b>10% de descuento adicional </b>si eres UTPL Alumni.
              </p>
              <p class="yellow">
                <b>Becas:</b>
              </p>
              <p>    Si confirmaste tu cupo postula a una de nuestras{" "}
              <b>becas Ser Más</b>.</p>
              <a
                class="link-with-arrow"
                target="_blank"
                href="https://becas.utpl.edu.ec/convenio"
              >
                Postula hoy mismo
              </a>
              <a
                class="link-with-arrow"
                target="_blank"
                href="https://becas.utpl.edu.ec"
              >
                Descubre más opciones de becas diseñadas para ti
              </a>
              <h4>
                <b>💳 ¡Difiere tu matrícula </b> <br></br> hasta 12 meses sin
                intereses!
              </h4>
              <a
                class="link-with-arrow"
                target="_blank"
                href="https://utpl.edu.ec/documentos/opcionespago.pdf"
              >
                Conoce aquí nuestras opciones de pago
              </a>
              <Accordion
                title="Otros descuentos"
                content={
                  <>
                    <ul>
                      <li>
                        <p>
                          50% de descuento por reingreso. No se combina con
                          otras becas ni descuentos.
                        </p>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
              <Accordion
                title="Plan de pagos"
                content={
                  <>
                    <p>
                      <b>Plan de pagos:</b>
                    </p>
                    <p>
                      <b>
                        Si optas por un plan de pagos, no obtendrás el descuento
                        por forma de pago.
                      </b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          Paga 50% al momento de la matrícula y 50% hasta el 2
                          de junio de 2024
                        </p>
                      </li>
                      <li>
                        <p>
                          Paga 40% al momento de la matrícula y 30% hasta el 15
                          de mayo de 2025 y 30% hasta el 1 de julio de 2025
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>Internados rotativos:</b>
                    </p>

                    <ul>
                      <li>
                        <p>
                          Paga 50% al momento de la matrícula y 50% a 180 días
                          plazo
                        </p>
                      </li>
                      <li>
                        <p>
                          Paga 25% al momento de la matrícula, 25% a 60 días,
                          25% a 120 días y 25% a 180 días
                        </p>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
            </>
          }
        ></BlockLeftImage>
      </div>
    </>
  );
}

export default PresencialPagos;


 



