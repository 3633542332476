import BlockLeftImage from "./BlockLeftImage";
import Accordion from "./Accordion";

import "../css/blockpagos.css";

function EnLineaPagos() {
  return (
    <>
      <div className="icon350 descuentos-section" id="descuentos">
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/costos.gif"
          content={
            <>
              <h3>
                Obtén hasta un <b>45% de descuento </b>en tu matrícula{" "}
              </h3>
              <p>
                <p className="yellow">
                  <b>Descuentos:</b>
                </p>
                🟢 <b>12% de descuento </b> hasta el 01 de abril. 🎓✨
              </p>
              <p>
                🟢 <b>10% de descuento adicional </b>si eres UTPL Alumni.
              </p>
              <p className="yellow">
                <b>Becas:</b>
              </p>
              <p>
                🟢 <b> 10% de descuento adicional</b> por Beca Convenio.
              </p>
              <a
                className="link-with-arrow"
                target="_blank"
                href="https://becas.utpl.edu.ec/convenio"
              >
                Postula hoy mismo
              </a>
              <br></br>
              <a
                className="link-with-arrow"
                target="_blank"
                href="https://becas.utpl.edu.ec"
              >
               Descubre más opciones de becas diseñadas para ti
              </a>
              <h4>
                <b>💳 ¡Difiere tu matrícula </b> <br></br> hasta 12 meses sin
                intereses!
              </h4>
              <a
                className="link-with-arrow"
                target="_blank"
                href="https://utpl.edu.ec/documentos/opcionespago.pdf"
              >
                Conoce aquí nuestras opciones de pago
              </a>
              <br></br> <br></br>
              <Accordion
                title="Otros descuentos"
                content={
                  <>
                    <p>
                      <b>50% de descuento</b> por reingreso. No se combina con
                      otras becas ni descuentos.{" "}
                    </p>
                  </>
                }
              ></Accordion>
              <Accordion
                title="Plan de pagos"
                content={
                  <>
                    <p>
                      <b>Plan de pagos:</b>
                    </p>
                    <p>
                      <b>
                        Si optas por un plan de pagos, no obtendrás el descuento
                        por forma de pago.
                      </b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          Paga 50% al momento de la matrícula y 50% hasta el 2
                          de junio de 2025.
                        </p>
                      </li>
                      <li>
                        <p>
                          Paga 40% al momento de la matrícula y 30% hasta el 15
                          de mayo de 2025 y 30% hasta el 1 de julio de 2025.
                        </p>
                      </li>
                    </ul>
                    <p></p>
                  </>
                }
              ></Accordion>
            </>
          }
        ></BlockLeftImage>
      </div>
    </>
  );
}

export default EnLineaPagos;
